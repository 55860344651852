import React from "react";
import SmallHeader from "./smallheader";
import { Fade, Slide } from 'react-awesome-reveal';
import { Helmet } from "react-helmet";

const Autos = () => {
  return (
    <div>
      <Helmet>
        <title>Onze Auto's - ELSDONCK BV</title>
        <meta name="description" content="Ontdek onze luxe auto's, waaronder Audi A6, Audi A8 Limousine en Audi Q7." />
        <meta property="og:title" content="Onze Auto's - ELSDONCK BV" />
        <meta property="og:description" content="Ontdek onze luxe auto's, waaronder Audi A6, Audi A8 Limousine en Audi Q7." />
        <meta property="og:image" content="/logo/ElsdonckPng.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@elsdonckbv" />
        <meta property="og:title" content="Onze Auto's - ELSDONCK BV" />
        <meta property="og:description" content="Ontdek onze luxe auto's, waaronder Audi A6, Audi A8 Limousine en Audi Q7." />
        <meta property="og:image" content="/logo/ElsdonckPng.png" />
      </Helmet>

      <SmallHeader title="Onze Auto's"></SmallHeader>
      <div class="bg-white">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-4 px-4 py-12 sm:px-6  lg:max-w-7xl lg:grid-cols-2 lg:px-8">
          <div class="flex flex-col justify-center">
            <Slide damping={1} duration={2000} className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Audi A8L</Slide>
            <Slide damping={1} duration={2000} className="mt-4 text-gray-500">
            De Audi A8L belichaamt pure luxe en verfijning op een ongeëvenaarde manier. Met zijn gracieuze ontwerp en onmiskenbare aanwezigheid op de weg, straalt deze limousine klasse en prestige uit. Stap in en ervaar het ongekende comfort van het ruime interieur, doordrenkt met hoogwaardige materialen en cutting-edge technologieën. Of je nu elegant door de stad rijdt of over lange afstanden reist, de Audi A8L biedt een soepel en weelderig rijgedrag, vergezeld van indrukwekkende prestaties en geavanceerde veiligheidsvoorzieningen.            </Slide>
          </div>
          <div class="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
            <div class="col-span-2 flex justify-center items-center">
              <div class="w-3/4 rounded-lg bg-gray-100 aspect-video" style={{ backgroundImage: `url("/cars/AudiA8L.jpg")`, backgroundSize: "cover", backgroundPosition: "center" }} />
            </div>
            <div class="rounded-lg bg-gray-100 aspect-video" style={{ backgroundImage: `url("/cars/AudiA8LGrijs.jpg")`, backgroundSize: "cover", backgroundPosition: "center" }} />
            <div class="rounded-lg bg-gray-100 aspect-video" style={{ backgroundImage: `url("/cars/Interieur.jpg")`, backgroundSize: "cover", backgroundPosition: "center" }} />
          </div>
        </div>
      </div>
      <div class="bg-gray-100">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-4 px-4 py-12 sm:px-6  lg:max-w-7xl lg:grid-cols-2 lg:px-8">
          <div class="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
            <div class="col-span-2 flex justify-center items-center">
              <div class="w-3/4 rounded-lg bg-gray-100 aspect-video" style={{ backgroundImage: `url("/cars/AudiA8L_Front.jpg")`, backgroundSize: "cover", backgroundPosition: "center" }} />
            </div>
            <div class="rounded-lg bg-gray-100 aspect-video" style={{ backgroundImage: `url("/cars/AudiA8LGreen.jpg")`, backgroundSize: "cover", backgroundPosition: "bottom" }} />
            <div class="rounded-lg bg-gray-100 aspect-video" style={{ backgroundImage: `url("/cars/Interieur.jpg")`, backgroundSize: "cover", backgroundPosition: "center" }} />
          </div>
          <div class="flex flex-col justify-center">
            <Slide damping={1} duration={2000} direction="right" className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Audi A8L</Slide>
            <Slide damping={1} duration={2000} direction="right" className="mt-4 text-gray-500">
            De Audi A8L is een meesterwerk van innovatie en elegantie, een limousine die de standaard voor luxe opnieuw definieert. Met zijn tijdloze design en imposante uitstraling trekt deze auto alle ogen naar zich toe, waar je ook bent. Betreed het weelderige interieur en laat je omringen door verfijning, met gebruik van luxe materialen en geavanceerde technologische snufjes. Of je nu de stad doorkruist of lange reizen maakt, de Audi A8L biedt een ongeëvenaarde mix van comfort en prestaties, ondersteund door geavanceerde veiligheidsvoorzieningen.            </Slide>
          </div>
        </div>
      </div>
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-4 px-4 py-12 sm:px-6  lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div class="flex flex-col justify-center">
          <Slide damping={1} duration={2000} className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Audi Q7</Slide>
          <Slide damping={1} duration={2000} className="mt-4 text-gray-500">
            Deze luxe SUV combineert kracht, stijl en veelzijdigheid op een meesterlijke wijze. Met zijn indrukwekkende formaat en robuuste uitstraling weet de Audi Q7 de aandacht te trekken, waar je ook gaat. Stap in en laat je verwennen door het ruime en luxueuze interieur, dat is voorzien van hoogwaardige materialen en geavanceerde technologieën. Of je nu op weg bent naar de stad of de natuur verkent, de Audi Q7 biedt je een soepele en comfortabele rijervaring, gecombineerd met indrukwekkende prestaties en geavanceerde veiligheidsvoorzieningen
          </Slide>
        </div>
        <div class="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          <div class="col-span-2 flex justify-center items-center">
            <div class="w-3/4 rounded-lg bg-gray-100 aspect-video" style={{ backgroundImage: `url("/cars/AudiQ7_side.png")`, backgroundSize: "cover", backgroundPosition: "center" }} />
          </div>
          <div class="rounded-lg bg-gray-100 aspect-video" style={{ backgroundImage: `url("/cars/AudiQ7_Interieur.jpg")`, backgroundSize: "cover", backgroundPosition: "center" }} />
          <div class="rounded-lg bg-gray-100 aspect-video" style={{ backgroundImage: `url("/cars/AudiQ7_Interieur_2.png")`, backgroundSize: "cover", backgroundPosition: "center" }} />
        </div>
      </div>
    </div>
  );
}

export default Autos;
