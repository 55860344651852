import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import Cookies from "universal-cookie";

const Logout = () => {
  const [response, setResponse] = useState();
  const cookies = new Cookies();
  const navigate = useNavigate();

  if (cookies.get("sesid") == "") navigate("/");

  fetch("http://localhost:3001/api/logout/" + cookies.get("sesid"), {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((res) => res.json())
    .then((data) => setResponse(data));
  console.log(response);
  if (response == "logged out") {
    cookies.set("sesid", "", { path: "/" });
    navigate("/");
  }
};
export default Logout;
