// UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if(cookies.get('sesid') == null) return;
    const fetchUser = async () => {
      try {
        const response = await fetch('http://localhost:3001/api/user/' + cookies.get('sesid'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({})
        });
        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ user }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;