import React from "react";
import { Fade, Zoom } from 'react-awesome-reveal';

class SmallHeader extends React.Component {
    render() {
        return <div>
            <div
                style={{
                    backgroundImage: "url('/external/background.jpg')",
                    backgroundSize: "cover",
                    backgroundPosition: "bottom",
                    backdropFilter: "blur(10px)",
                }}
                class="-mt-20 bg-white h-4/6 oveWrflow-hidden relative"
            >
                <div class="container flex grid lg:grid-cols-3 py-12 content-center">
                    <div class="m-auto text-start col-span-2 py-12 pt-4 sm:px-6 lg:pt-16 lg:px-8 z-20 lg:mt-0 mt-12">
                        <p class="text-1xl sm:text-2xl text-blue-400"><Zoom duration={200} cascade left damping triggerOnce direction="down">ELSDONCK BV</Zoom></p>
                        <p class="text-4xl sm:text-6xl text-white font-black">
                            <Fade direction="down" triggerOnce>{this.props.title}</Fade>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default SmallHeader;