import React, { useEffect } from "react";
import Navigation from "./navigation";
import Footer from "./footer";
import { Outlet } from "react-router-dom";


class Layout extends React.Component {
  render() {
    return (
      <div>
        <Navigation></Navigation>
        <Outlet></Outlet>
        <Footer></Footer>
      </div>
    );
  }
}
export default Layout;
