import "./App.css";
import React from "react";
import Home from "./components/home";
import Login from "./components/login";
import Contact from "./components/contact";
import Logout from "./components/logout";
import Users from "./components/users";
import Autos from "./components/autos";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/layout";
import UserContextProvider from "./context/UserContext";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <UserContextProvider>
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={1000}
            classNames="fade"
          >
            <Routes location={location} key={location.pathname}>
              <Route path="/google6ee8a5b8d9a022bc.html" element={"google-site-verification: google6ee8a5b8d9a022bc.html"}></Route>
              <Route path="/" element={<Layout />}>
                {/* Render the Layout component here */}
                <Route index element={<Home />}></Route> {/* Render the Home component here */}
                <Route path="autos" element={<Autos />}></Route> {/* Render the Autos component here */}
                <Route path="contact" element={<Contact />} /> {/* Render the Contact component here */}
                <Route path="users" element={<Users />} /> {/* Render the Users component here */}
                <Route path="login" element={<Login />} /> {/* Render the Login component here */}
                <Route path="logout" element={<Logout />} /> {/* Render the Logout component here */}
              </Route>
            </Routes>

          </CSSTransition>
        </TransitionGroup>
      </UserContextProvider>
    </div>
  );
}

export default App;
