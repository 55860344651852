import React, { useState } from "react";
import SmallHeader from "./smallheader";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet";

const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        telephone: "",
        email: "",
        pickupPoint: "",
        destination: "",
        time: "",
        date: "",
        baggage: "", // Toegevoegd voor de bagage specificatie
        persons: "",
        paymentMethod: "", // Added field for payment method
    });

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();

        // Controleer op ontbrekende velden
        const formErrors = {};
        for (const field in formData) {
            if (!formData[field]) {
                formErrors[field] = "Dit veld is verplicht.";
            }
        }

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        // Stuur het taxiverzoek naar EmailJS
        emailjs
            .send(
                "service_ivq6wjp",
                "template_8nj8nd7",
                formData,
                "g41_zipiGcp10F6yh"
            )
            .then((response) => {
                console.log("E-mail verzonden!", response);
                setIsSubmitted(true);
            })
            .catch((error) => {
                console.error("Fout bij het verzenden van de e-mail:", error);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        // Verwijder het foutbericht als het veld opnieuw wordt ingevuld
        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: null,
            }));
        }
    };

    if (isSubmitted) {
        return (
            <div>
                
                <SmallHeader title="Contact"></SmallHeader>
                <div className="isolate bg-white px-6 py-32 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <img
                            src={"/check.png"}
                            alt="Checkmark"
                            className="mx-auto w-24 mb-4"
                        />
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Offerte Verzonden
                        </h2>
                        <p className="mt-2 text-lg leading-9 text-gray-600">
                            Uw offerte aanvraag is succesvol verzonden. We nemen binnenkort
                            contact met u op.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Helmet>
                <title>Contact - ELSDONCK BV</title>
                <meta name="description" content="Neem contact met ons op voor offerteaanvragen en vragen over onze diensten." />
                <meta property="og:title" content="Contact - ELSDONCK BV" />
                <meta property="og:description" content="Neem contact met ons op voor offerteaanvragen en vragen over onze diensten." />
                <meta property="og:image" content="/logo/ElsdonckPng.png" />
                <meta property="og:url" content="https://elsdonckbv.be/" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contact - ELSDONCK BV" />
                <meta name="twitter:description" content="Neem contact met ons op voor offerteaanvragen en vragen over onze diensten." />
                <meta name="twitter:image" content="/logo/ElsdonckPng.png" />
            </Helmet>
            <SmallHeader title="Contact"></SmallHeader>
            <div className="isolate bg-white px-6 py-12 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Offerte Opstellen
                    </h2>
                    <p className="mt-2 text-lg leading-8 text-gray-600">
                        Hier kan je uw Offerte op maat aanvragen.
                    </p>
                </div>
                <form
                    action="#"
                    method="POST"
                    className="mx-auto mt-8 max-w-xl sm:mt-12"
                    onSubmit={handleSubmit}
                >
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div>
                            <label
                                htmlFor="first-name"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                                Voornaam
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    name="firstName"
                                    id="first-name"
                                    placeholder="John"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    required
                                />
                                {errors.firstName && (
                                    <p className="text-red-600 text-sm mt-1">{errors.firstName}</p>
                                )}
                            </div>
                        </div>
                        <div>
                            <label
                                htmlFor="last-name"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                                Achternaam
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder="Doe"
                                    id="last-name"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    required
                                />
                                {errors.lastName && (
                                    <p className="text-red-600 text-sm mt-1">{errors.lastName}</p>
                                )}
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="telephone"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                                Telefoonnummer
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="tel"
                                    name="telephone"
                                    placeholder="(+32) 00000000"
                                    id="telephone"
                                    autoComplete="tel"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    required
                                />
                                {errors.telephone && (
                                    <p className="text-red-600 text-sm mt-1">{errors.telephone}</p>
                                )}
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="email"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                                E-mail
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="example@gmail.com"
                                    id="email"
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    required
                                />
                                {errors.email && (
                                    <p className="text-red-600 text-sm mt-1">{errors.email}</p>
                                )}
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="persons"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                                Aantal Personen
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="number"
                                    name="persons"
                                    id="persons"
                                    placeholder="1"
                                    min="1" // You can set a minimum number of persons, e.g., 1.
                                    autoComplete="off"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    required
                                />
                                {errors.persons && (
                                    <p className="text-red-600 text-sm mt-1">{errors.persons}</p>
                                )}
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="pickup-point"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                                Ophaalpunt
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    name="pickupPoint"
                                    placeholder="Straat, PostCode, Stad"
                                    id="pickup-point"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    required
                                />
                                {errors.pickupPoint && (
                                    <p className="text-red-600 text-sm mt-1">{errors.pickupPoint}</p>
                                )}
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="destination"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                                Bestemming
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    name="destination"
                                    placeholder="Straat, PostCode, Stad"
                                    id="destination"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    required
                                />
                                {errors.destination && (
                                    <p className="text-red-600 text-sm mt-1">{errors.destination}</p>
                                )}
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="time"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                                Tijd
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="time"
                                    name="time"
                                    id="time"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    required
                                />
                                {errors.time && (
                                    <p className="text-red-600 text-sm mt-1">{errors.time}</p>
                                )}
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="date"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                                Datum
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="date"
                                    name="date"
                                    id="date"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    required
                                />
                                {errors.date && (
                                    <p className="text-red-600 text-sm mt-1">{errors.date}</p>
                                )}
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="baggage"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                                Bagage specificatie
                            </label>
                            <div className="mt-2.5">
                                <textarea
                                    name="baggage"
                                    id="baggage"
                                    placeholder="4 Koffers"
                                    rows="4"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    required
                                ></textarea>
                                {errors.baggage && (
                                    <p className="text-red-600 text-sm mt-1">{errors.baggage}</p>
                                )}
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label
                                htmlFor="payment-method"
                                className="block text-sm font-semibold leading-6 text-gray-900"
                            >
                                Betaalmethode
                            </label>
                            <div className="mt-2.5">
                                <select
                                    name="paymentMethod"
                                    id="payment-method"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled selected>
                                        -- Selecteer Betaalmethode --
                                    </option>
                                    <option value="Cash">Cash</option>
                                    <option value="Bancontact">Bancontact</option>
                                    <option value="Factuur">Factuur</option>
                                </select>
                                {errors.paymentMethod && (
                                    <p className="text-red-600 text-sm mt-1">{errors.paymentMethod}</p>
                                )}
                            </div>
                        </div>

                    </div>
                    <div className="mt-10">
                        <button
                            type="submit"
                            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Verstuur aanvraag
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Contact;
