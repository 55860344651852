import React, { useEffect } from "react";
import Header from "./header";
import { Helmet } from "react-helmet";

import { Flip, Fade, Slide, heartBeat } from 'react-awesome-reveal';
function Home() {
  return (
    <div>
      <Helmet>
        <title>Home - ELSDONCK BV</title>
        <meta name="description" content="Taxi Service voor Luchthaven vervoer, evenementen vervoer en VIP vervoer." />

        <meta property="og:title" content="Onze Auto's - ELSDONCK BV" />
        <meta property="og:description" content="Taxi Service voor Luchthaven vervoer, evenementen vervoer en VIP vervoer." />
        <meta property="og:image" content="/logo/ElsdonckPng.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@elsdonckbv" />
        <meta property="og:title" content="Home - ELSDONCK BV" />
        <meta property="og:description" content="Taxi Service voor Luchthaven vervoer, evenementen vervoer en VIP vervoer." />
        <meta property="og:image" content="/logo/ElsdonckPng.png" />
      </Helmet>
      <Header></Header>
      <div>
        <div class="container p-6 px-6 mx-auto bg-white my-8">
          <div class="mb-16 text-center">
            <h2 class="text-base font-semibold tracking-wide text-indigo-600 uppercase">
              SERVICES
            </h2>
            <p class="mt-2 text-3xl font-extrabold leading-8 tracking-tight sm:text-4xl">
              Waarom Elsdonck BV?
            </p>
          </div>
          <div class="flex flex-wrap my-12">
            <div class="w-full p-8 md:w-1/2 md:border-r lg:w-1/3">
              <div class="flex justify-center items-center mb-6">
                <Slide className="text-indigo-500" direction="down">
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="w-6 h-6"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"></path>
                  </svg>
                </Slide>
                <div class="ml-4 text-xl"><Slide direction="down">Comfortabel en Luxe</Slide></div>
              </div>
              <Fade>
                <p class="leading-loose text-gray-500 text-md">
                  Elsdonck BV biedt een vloot van luxe voertuigen aan, met ruime interieurs, lederen bekleding en geavanceerde technologie. Onze auto's zijn ontworpen om comfort en stijl te bieden, waardoor onze passagiers in alle luxe kunnen reizen.              </p>
              </Fade>
            </div>
            <div class="w-full p-8 md:w-1/2 lg:w-1/3 lg:border-r">
              <div class="flex justify-center items-center mb-6">
                <Slide className="text-indigo-500" direction="down">
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="w-6 h-6"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"></path>
                  </svg>
                </Slide>
                <div class="ml-4 text-xl text-center"><Slide direction="down">Professionele Chauffeurs</Slide></div>
              </div>
              <Fade bottom>
                <p class="leading-loose text-gray-500 text-md">
                  Bij Elsdonck BV stellen we hoge eisen aan onze chauffeurs. Ze zijn deskundig opgeleid, ervaren en klantgericht. Onze chauffeurs combineren uitstekende rijvaardigheden met een vriendelijke en professionele benadering, waardoor onze passagiers zich op hun gemak voelen tijdens de rit.              </p>
              </Fade>
            </div>
            <div class="w-full p-8 md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0">
              <div class="flex items-center justify-center mb-6">
                <Slide className="text-indigo-500" direction="down">
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="w-6 h-6"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"></path>
                  </svg>
                </Slide>
                <div class="ml-4 text-center text-xl"><Slide direction="down">Betrouwbaar en Stiptheid</Slide></div>
              </div>
              <Fade bottom>
                <p class="leading-loose text-gray-500 text-md">
                  Bij Elsdonck BV begrijpen we het belang van betrouwbaarheid en stiptheid als het gaat om vervoer. Onze chauffeurs zijn altijd op tijd aanwezig, zodat onze passagiers zonder stress kunnen reizen.</p>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-100 overflow-hidden relative lg:flex lg:items-center">
        <div class="w-full py-4 px-4 sm:px-6 lg:px-8">
          <h2 class="text-3xl font-extrabold text-black sm:text-4xl">
            <span class="block">
              <Slide direction="down">Uw vakantie of zakelijke reis begint bij ons!</Slide>
            </span>
          </h2>
          <p class="text-md mt-4 text-gray-400">
            <Fade>Het antwoord is eenvoudig: we zijn afhankelijk van uw geluk, dus we gaan verder dan dat om ons doel te bereiken met onze vervoersdiensten. U zult merken dat wij de beste zijn op alle gebied, van de kwaliteit van de voertuigen tot de professionele chauffeurs en de klantenservice. Onze prijzen zijn eerlijk, en ons personentransport is iets waar je geen prijs op kunt plakken!
              Als familiaal bedrijf bieden we reeds onze service aan van september 1988 en zijn een begrip in onze sector!
              Overtuig uzelf en contacteer ons</Fade>         </p>
        </div>
        <div class="sm:flex items-center gap-8 p-8 lg:p-24">
          <div class="sm:w-1/2">
            <Fade damping={1} duration={2000} childClassName="sm:h-50 ml-0 mb-8 lg:mb-0 lg:w-full rounded-lg"><img src="/cars/AudiQ7_side.png" alt="Tree" /></Fade>
          </div>
          <div class="sm:w-1/2">
            <Fade damping={1} duration={2000} className="ml-0" childClassName="sm:h-50 lg:h-40 w-full mb-8 rounded-lg"><img src="/cars/AudiA6.png" alt="Tree" /></Fade>
            <Fade damping={1} duration={2000} className="ml-0" childClassName="sm:h-50 lg:h-40 w-full rounded-lg"><img src="/cars/AudiA8.png" alt="Tree" /></Fade>
          </div>
        </div>
      </div>
      <div class="bg-white  py-24 sm:py-32 justify-center m-auto items-center">
        <div class="mx-auto lg:flex max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
          <div class="max-w-2xl justify-center items-center text-center m-auto">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"><Slide direction="down">Ons team</Slide></h2>
            <p class="mt-6 text-lg leading-8 text-gray-600"><Fade>Ons team van gepassioneerde chauffeurs vormt de betrouwbare spil van ons taxibedrijf, altijd klaar om onze klanten veilig en comfortabel naar hun bestemming te brengen.</Fade></p>
          </div>
          <ul role="list" class="pt-8 lg:pt-0 place-items-center grid gap-x-8 gap-y-12 sm:grid-cols-2 justify-center items-center m-auto sm:gap-y-16 xl:col-span-2">
            <li>
              <div class="flex items-center gap-x-6">
                <Fade direction="up" className="sm:hidden xl:block" childClassName="h-16 w-16 rounded-full"><img src="/team/EddyNuyten.jpg" alt="" /></Fade>
                <div>
                  <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900"><Slide className="text-center" direction="down">Eddy Nuyten</Slide></h3>
                  <p class="text-sm font-semibold leading-6 text-indigo-600"><Slide direction="down">Founder</Slide></p>
                </div>
              </div>
            </li>
            <li>
              <div class="flex items-center gap-x-6">
                <Fade direction="up" className="sm:hidden xl:block" childClassName="h-16 w-16 rounded-full"><img src="/team/KevinNuyten.jpg" alt="" /></Fade>
                <div class="md:">
                  <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900"><Slide direction="down">Kevin Nuyten</Slide></h3>
                  <p class="text-sm font-semibold leading-6 text-indigo-600"><Slide direction="down">Co-Founder</Slide></p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Home;
