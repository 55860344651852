import React, { useState, useEffect } from "react";
import Header from "./header";

const Users = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch("http://elsdonckbv.be:3001/api/users", {
      method: "POST",
      body: JSON.stringify({}),
      headers: {  
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => setUsers(data));
  }, []);

  return (
    <div>
      <Header></Header>
      <div class="px-5 py-10 bg-white dark:bg-gray-200">
        <h1 class="text-center text-3xl block font-bold">Users</h1>
        <p class="mb-5">See all users of the website</p>
        <div class="flex justify-center py-3">
          {users.length > 0 ? (
            users.map((user) => (
              <div class="w-64 ml-5 mr-5 bg-white shadow-lg rounded-2xl dark:bg-gray-800">
                <img
                  alt="profile"
                  src="https://www.tailwind-kit.com/images/landscape/1.jpg"
                  class="w-full rounded-2xl mb-4 rounded-t-lg h-28"
                />
                <div class="flex flex-col items-center justify-center p-4 -mt-16">
                  <a href="#" class="relative block">
                    <img
                      alt="Avatar"
                      src={user.avatar}
                      class="mx-auto object-cover rounded-full h-16 w-16 "
                    />
                  </a>
                  <p class="mt-2 text-xl font-medium text-gray-800 dark:text-white">
                    {user.name}
                  </p>
                  <p class="text-xs text-gray-400">
                    {user.is_admin === 1 ? "Admin" : "User"}
                  </p>
                  <div class="flex items-center justify-between w-full gap-4 mt-8">
                    <button
                      type="button"
                      class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                    >
                      See profile
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div class="w-64 ml-5 mr-5 bg-white shadow-lg rounded-2xl dark:bg-gray-800">
              <div class="h-28 p-3 overflow-hidden bg-gray-200 dark:bg-gray-500 animate-pulse rounded-2xl"></div>
              <div class="p-3">
                <div class="-mt-12 mx-auto object-cover bg-gray-200 rounded-full h-16 w-16 animate-pulse" />
                <div class="grid grid-cols-3 gap-4 mt-2">
                  <div class="col-span-1"></div>
                  <div class="h-8 bg-gray-200 rounded animate-pulse"></div>
                  <div class="col-span-1"></div>
                  <div class="col-span-3 w-full gap-4">
                    <button
                      type="button"
                      disabled
                      class="animate-pulse py-2 px-4  bg-indigo-800 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                    >
                      Loading...
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Users;
