import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";

const Navigation = () => {
  const { user } = useContext(UserContext);
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Calculate screen height
    const screenHeight = window.innerHeight;
    if(location.pathname != "/") {
      const screenHeight = window.innerHeight/100*50;
    }
    // Calculate scroll threshold to trigger the change
    const scrollThreshold = screenHeight;

    // Check if the scroll position exceeds the threshold
    setIsScrolled(scrollPosition > scrollThreshold);
  }, [scrollPosition]);

  const [show, setShow] = useState(false);

  const changeVisibility = () => {
    setShow(!show)
  }

  return (
    <div class="sticky top-0 z-50">
      <div>
        <nav className={`top-0 left-0 right-0 py-4 ${isScrolled ? 'bg-slate-900' : 'bg-transparent'} ${show && "!bg-slate-900"}`}>
          <div class="px-8 mx-auto">
            <div class="flex items-center h-12">
              <a class="flex-shrink-0" href="/">
                <img class="w-16 h-16" src="/logo/ElsdonckPng.png" alt="Workflow" />
              </a>
              <div class=" flex items-end ml-auto">
                <div class="hidden md:block">
                  <div class="flex items-baseline ml-10 space-x-4">
                    <Link
                      to="/"
                      className={`text-gray-300 hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-md font-medium`}
                    >
                      Home
                    </Link>
                    <Link
                      class="text-gray-300  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-md font-medium"
                      to="/autos"
                    >
                      Auto's
                    </Link>
                    <Link
                      class="text-gray-300  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-md font-medium"
                      to="/contact"
                    > 
                      Contact
                    </Link>
                  </div>
                </div>
              </div>
              <div class="block">
                <div class="flex items-center ml-4 md:ml-6"></div>
              </div>
              <div class="flex -mr-2 md:hidden">
                <button onClick={changeVisibility} class="text-gray-800 dark:text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none">
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="w-8 h-8"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className={`md:hidden ${show ? "visible" : "invisible"}`}>
            <div className={`md:hidden px-2 pt-2 mt-4 pb-3 absolute space-y-1 sm:px-3 w-full ${show && "bg-slate-800"}`}>
              <Link
                class="text-gray-300 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                to="/"
              >
                Home
              </Link>
              <Link
                class="text-gray-300 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                to="/autos"
              >
                Autos
              </Link>
              <Link
                class="text-gray-300 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                to="/contact"
              >
                Contact
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
