import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Flip, Fade, Zoom, Roll, AttentionSeeker } from 'react-awesome-reveal';

const Header = () => {
  const handleScroll = () => {
    const scrollHeight = window.innerHeight;
    window.scrollTo({
      top: scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: "url('/external/background.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backdropFilter: "blur(10px)",
        }}
        class="-mt-20 bg-white h-screen overflow-hidden relative"
      >
        <div class="container items-center content-center mx-auto sm:m-auto lg:mt-0 flex lg:grid lg:grid-cols-3 h-screen p-5">
          <div class="m-auto lg:text-start max-md:mb-4 sm:grid sm:text-center lg:col-span-2 py-12 pt-4 sm:px-6 lg:pt-16 lg:px-8 z-20">
            <img src={"/logo/ElsdonckPng.png"} class="text-2xl max-lg:mb-5 max-lg:m-auto w-32 h-32 text-blue-400 mb-5"/>
            <p class="text-3xl md:text-6xl text-white text-uppercase text-blue-300 font-black">ELSDONCK BV</p>
            <p class="text-2xl md:text-4xl text-white text-uppercase">WHEN IT BECOMES PERSONAL</p>
            <div class="lg:mt-0 lg:flex-shrink-0">
              <div class="mt-12 inline-flex rounded-md">
                <Link
                  to={"/contact"}
                  type="button"
                  class="py-4 px-6 bg-blue-800 hover:bg-blue-900 focus:ring-blue-600 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                >
                  <AttentionSeeker effect="pulse">
                    uw Persoonlijke Offerte
                  </AttentionSeeker>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
export default Header;
